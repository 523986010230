import { navigate, RouteComponentProps, Router } from '@reach/router';
import {
  InquiryModal,
  InquiryModalData,
} from '../../../components/src/components/DesignSystemV2/Elements/InquiryModal/InquiryModal';
import * as React from 'react';
import { makeCreateUserEventRequest } from '../api/makeCreateUserEventRequest';
import Layout from '../components/Layout/Layout';
import { getEmail } from '../utils/cookies';

const APP_URL = process.env['GATSBY_APP_URL'];

const basePath = `/onboarding`;

export const preOnboardingSteps: InquiryModalData[] = [
  {
    title: 'Czy jesteś Vatowcem?',
    route: 'czy-jestes-vatowcem',
    options: [
      { id: '1', label: 'tak' },
      { id: '2', label: 'nie' },
    ],
  }, {
    title: 'Jaki podatek?',
    route: 'jaki-podatek',
    options: [
      { id: '1', label: 'skala podatkowa' },
      { id: '2', label: 'podatek liniowy' },
      { id: '3', label: 'ryczałt' },
    ],
  }, {
    title: 'Jaki ZUS?',
    route: 'jaki-zus',
    options: [
      { id: '1', label: 'mały ZUS' },
      { id: '2', label: 'duży ZUS' },
    ],
  }, {
    title: 'Częstotliwość rozliczania',
    route: 'czestotliwosc-rozliczania',
    options: [
      { id: '1', label: 'Co miesiąc' },
      { id: '2', label: 'Co kwartał' },
    ],
  }, {
    title: 'Co Cię interesuje?',
    route: 'co-cie-interesuje',
    options: [
      { id: '1', label: 'Faktury' },
      { id: '2', label: 'Księgowość' },
      { id: '3', label: 'Obsługa przez biuro rachunkowe' },
      { id: '4', label: 'Inne' },
    ],
  },
];

const finalStep: InquiryModalData = {
  title: 'dziękujemy za rejestrację',
  route: 'dziekujemy',
  options: [{
    id: 'finalStep',
    label: 'przejdź do aplikacji',
  }],
};

export const getPreOnboardingStepRoute = (stepRoute: string) => {
  return `${basePath}/${stepRoute}`;
};

const getCurrentStepIndex = (path: string): number => {
  const index = preOnboardingSteps
    .findIndex(step => step.route === path);

  return index === -1 ? 0 : index;
};

const ModalWithNavigationStateWrapper: React.FC<RouteComponentProps> = props => {
  const { location } = props;
  const [lastVisitedStep, setLastVisitedStep] = React.useState(0);
  const [isNextButtonDisabled, setNextButtonDisabled] = React.useState(true);

  const pathnameParts = location.pathname.split('/');
  const path = pathnameParts[pathnameParts.length - 1];

  const stepIdx = getCurrentStepIndex(path);

  const onBackButtonClickHandler = () => {
    window.history.back();
  };

  const onNextButtonClickHandler = () => {
    navigate(getPreOnboardingStepRoute(preOnboardingSteps[stepIdx + 1].route));
  };

  const onSelectHandler = async (options: { id: string, label: string }) => {
    await makeCreateUserEventRequest([preOnboardingSteps[stepIdx].title, options.label], getEmail());

    if (stepIdx !== preOnboardingSteps.length - 1) {
      navigate(getPreOnboardingStepRoute(preOnboardingSteps[stepIdx + 1].route));
    }
  };

  React.useEffect(() => {
    if (stepIdx > lastVisitedStep) {
      setLastVisitedStep(stepIdx);
    }

    setNextButtonDisabled(stepIdx >= lastVisitedStep);
  }, [stepIdx]);

  return (
    <>
      <InquiryModal
        isOpen
        data={preOnboardingSteps}
        currentStepIndex={stepIdx}
        finalStep={finalStep}
        onSelect={onSelectHandler}
        isBackButtonDisabled={false}
        isNextButtonDisabled={isNextButtonDisabled}
        onBackButtonClick={onBackButtonClickHandler}
        onNextButtonClick={onNextButtonClickHandler}
        onFinalStepButtonClick={() => {
          if (document.location.href.includes('test.fakturuj.to')) {
            document.location.href = 'https://app.test.fakturuj.to/';
          } else {
            document.location.href = APP_URL;
          }
        }}
      />
    </>
  );
};

const OnboardingPage = () => (
  <Layout>
    <Router basepath={basePath}>
      <ModalWithNavigationStateWrapper
        path={'/:stepName'}
        default
      />
    </Router>
  </Layout>
);

export default OnboardingPage;
