import { getThemeModeColors } from 'components/src/components/Layout/ThemeProvider/ThemeProvider';

const { primary3: primary, white } = getThemeModeColors();

export const colors = {
  white,
  black: '#000',
  primary: {
    normal: primary,
    light: '#E9F1FF',
    dark: '#062861',
    disabled: '#9fbcc6',
  },
};
