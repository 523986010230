import { ComponentSize } from '../../../../ComponentSize';

export const headerStyles = (size: ComponentSize) => ({
  small: {
    marginTop: '2rem',
    marginBottom: '3rem',
  },
  medium: {
    marginTop: '4.4rem',
    marginBottom: '2.5rem',
  },
}[size]);

export const titleStyles = (size: ComponentSize) => ({
  small: {
    fontSize: '2.4rem',
  },
  medium: {
    fontSize: '4.8rem',
  },
}[size]);
