import * as React from 'react';
import { EventOrValueHandler } from 'redux-form/lib/Field';
import { noop } from '../../../../../helpers/noop';
import { ComponentSize } from '../../../ComponentSize';
import { LabelHtmlElement, RadioElementContainer, RadioHtmlInputElement } from './RadioInput-styled';

interface RadioInputProps {
  value: any;
  id?: string;
  name?: string;
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  size?: ComponentSize;

  onChange?: EventOrValueHandler<React.ChangeEvent<any>>;
  asChecked?: boolean;
}

export const RadioInput: React.ComponentType<RadioInputProps> = props => {
  const { label, checked, value, onChange, id, disabled, asChecked, size, name } = props;

  const radioId = id || `radio_button_${value}`;

  return (
    <RadioElementContainer
      size={size || 'medium'}
      isReadOnly={!onChange || disabled}
    >
      <RadioHtmlInputElement
        id={radioId}
        name={name}
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={disabled ? noop : onChange}
      />
      <LabelHtmlElement
        asChecked={asChecked}
        isDisabled={disabled}
        isChecked={checked}
        htmlFor={radioId}
        size={size || 'medium'}
      >
        {label}
      </LabelHtmlElement>
    </RadioElementContainer>
  );
};
