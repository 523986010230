import { darken } from 'polished';
import styled, { css } from 'styled-components';
import { ComponentSize } from '../../../ComponentSize';
import { labelHtmlElementStyles, radioElementContainerReadOnlyStyles, radioElementContainerStyles } from './mixins';

export const RadioHtmlInputElement = styled.input.attrs({ type: 'radio' })`
  position: absolute;
  opacity: 0;
`;

type LabelHtmlElementProps = {
  size: ComponentSize;
  isChecked?: boolean;
  isDisabled?: boolean;
  asChecked?: boolean;
};

export const LabelHtmlElement = styled.label<LabelHtmlElementProps>`
  user-select: none;
  cursor: pointer;

  &:before {
    content: '';
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 100%;
    border: 0.1rem solid ${({ theme }) => theme.colors.primary5};
    display: inline-block;
    width: ${({ theme, size }) => labelHtmlElementStyles(size, theme).width};
    height: ${({ theme, size }) => labelHtmlElementStyles(size, theme).height};
    position: relative;
    top: 0;
    margin-right: 1.5rem;
    vertical-align: middle;
    cursor: pointer;
    text-align: center;
    transition: all 250ms ease;
  }
  &:hover {
    &:before {
      border-color: ${({ theme }) => theme.colors.black};
    }
  }
  ${({ asChecked, isChecked }) => (asChecked || isChecked) && css<{ size: ComponentSize }>`
    &:before {
      background-color: ${({ theme }) => theme.colors.primary5};
      box-shadow: ${({ theme, size }) => labelHtmlElementStyles(size, theme).boxShadow};
    }
  `}
  ${({ isDisabled, asChecked, isChecked }) => isDisabled && !asChecked && !isChecked && css<{ size: ComponentSize }>`
    &:before {
      border-color: ${({ theme }) => darken(.25, theme.colors.white)};
      box-shadow: ${({ theme, size }) => labelHtmlElementStyles(size, theme).boxShadow};
      background-color: transparent;
    }
  `}
  ${({ isDisabled, asChecked, isChecked }) => ((isDisabled && isChecked) || (isDisabled && asChecked))
  && css<{ size: ComponentSize }>`
    &:before {
      border-color: ${({ theme }) => darken(.25, theme.colors.white)};
      box-shadow: ${({ theme, size }) => labelHtmlElementStyles(size, theme).boxShadow};
      background-color: ${({ theme }) => darken(.25, theme.colors.white)};
    }
  `}
`;

type RadioElementContainerProps = {
  size: ComponentSize;
  isReadOnly?: boolean;
};

export const RadioElementContainer = styled.div<RadioElementContainerProps>`
  font-size: ${({ size }) => radioElementContainerStyles(size).fontSize};
  margin-bottom: ${({ size }) => radioElementContainerStyles(size).marginBottom};
  font-family: 'Roboto-Light', sans-serif;
  color: ${({ theme }) => theme.colors.primary5};

  ${({ isReadOnly }) => radioElementContainerReadOnlyStyles(isReadOnly)}
  ${RadioHtmlInputElement}:focus + ${LabelHtmlElement}:before {
    outline: none;
    border-color: ${({ theme }) => theme.colors.primary5};
  }
`;
