import styled from 'styled-components';
import { ComponentSize } from '../../ComponentSize';
import { Button } from '../Buttons/Button/Button';
import { containerStyles, navigationContainerStyles, progressBarContainerStyles } from './mixins';

export const Container = styled.div<{ size: ComponentSize }>`
  display: flex;
  flex-direction: column;

  ${props => {
  const styles = containerStyles(props.size);

  return `
  height: ${styles.height};
  width: ${styles.width};
  padding: ${styles.padding};
  `;
}}
`;

export const ProgressBarContainer = styled.div<{ size: ComponentSize }>`
  margin-top: ${props => progressBarContainerStyles(props.size).marginTop};
  margin-bottom: ${props => progressBarContainerStyles(props.size).marginBottom};
  height: .6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const RadioContainer = styled.div`
`;

export const NavigationContainer = styled.div<{ size: ComponentSize }>`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: ${props => navigationContainerStyles(props.size).paddingBottom};
`;

export const BackButton = styled(Button)`
  padding: .5rem;
  margin: 0 .5rem 1.5rem 0;

  > svg {
    transform: scale(1.4);
    margin: 0;
  }
`;

export const NextButton = styled(Button)`
  padding: 2rem;
  margin: 0 0 0 .5rem;
  border-radius: 50%;

  > svg {
    transform: rotate(180deg) scale(1.4);
    margin: 0;
  }
`;
