import * as React from 'react';
import { CloseButton } from 'components/src/components/Elements/Modal/CloseButton';
import { ComponentSize } from '../../../../ComponentSize';
import { CloseIcon, Header, Title } from './Header-styled';

const MODAL_TITLE_TEST_ID = 'MODAL_TITLE_TEST_ID';

interface OuterHeaderProps {
  onClickCloseIcon?: () => any;

  titleLabel?: string;
  hideCloseIcon?: boolean;
  size?: ComponentSize;
}

export const ModalHeader: React.ComponentType<OuterHeaderProps> = (props) => {
  const { titleLabel, onClickCloseIcon, hideCloseIcon, size, children } = props;
  return (
    <Header size={size || 'medium'}>
      <Title
        size={size || 'medium'}
        data-testid={MODAL_TITLE_TEST_ID}
      >
        {titleLabel}
      </Title>
      {children}
      {onClickCloseIcon && !hideCloseIcon ? (
        <CloseIcon noMarginAuto={Boolean(children)}>
          <CloseButton onClick={onClickCloseIcon}/>
        </CloseIcon>
      ) : null}
    </Header>
  );
};
