import { css } from 'styled-components';
import { Theme } from '../../../../Layout/ThemeProvider/ThemeProvider';
import { ComponentSize } from '../../../ComponentSize';

export const radioElementContainerStyles = (size: ComponentSize) => ({
  small: {
    fontSize: '1.8rem',
    marginBottom: '3.2rem',
  },
  medium: {
    fontSize: '1.4rem',
    marginBottom: '2.2rem',
  },
}[size]);

export const radioElementContainerReadOnlyStyles = (readOnly) => css`
  ${readOnly ? 'pointer-events: none;' : ''}
`;

export const labelHtmlElementStyles = (size: ComponentSize, theme: Theme) => ({
  small: {
    width: '2.5rem',
    height: '2.5rem',
    boxShadow: `inset 0 0 0 .5rem ${theme.colors.white}`,
  },
  medium: {
    width: '4rem',
    height: '4rem',
    boxShadow: `inset 0 0 0 .65rem ${theme.colors.white}`,
  },
}[size]);
