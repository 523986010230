import { ComponentSize } from '../../ComponentSize';

export const containerStyles = (size: ComponentSize) => ({
  small: {
    height: '42.5rem',
    width: '32rem',
    padding: '1rem 2.7rem',
  },
  medium: {
    height: '50rem',
    width: '100%',
    padding: '2rem 9.2rem',
  },
}[size]);

export const progressBarContainerStyles = (size: ComponentSize) => ({
  small: {
    marginTop: '.1rem',
    marginBottom: '0',
  },
  medium: {
    marginTop: '.7rem',
    marginBottom: '.5rem',
  },
}[size]);

export const navigationContainerStyles = (size: ComponentSize) => ({
  small: {
    paddingBottom: '.5rem',
  },
  medium: {
    paddingBottom: '.4rem',
  },
}[size]);
