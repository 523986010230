import styled from 'styled-components';
import { ComponentSize } from '../../../../ComponentSize';
import { headerStyles, titleStyles } from './mixins';

export const Header = styled.div<{ size: ComponentSize }>`
  display: flex;
  align-items: center;
  margin-top: ${props => headerStyles(props.size).marginTop};
  margin-bottom: ${props => headerStyles(props.size).marginBottom}
`;

export const Title = styled.div<{ size: ComponentSize }>`
  color: ${({ theme }) => theme.colors.primary5};
  font-family: 'Roboto-Bold', sans-serif;
  font-size: ${props => titleStyles(props.size).fontSize};
  font-weight: bold;
  width: 100%;
  overflow-wrap: break-word;
  overflow: hidden;
`;

export const CloseIcon = styled.div`
  ${(props: { noMarginAuto?: boolean }) => props.noMarginAuto ? null : 'margin-left: auto;'}
`;
