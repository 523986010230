import { exists, window } from 'browser-monads';
import { Modal, ModalProps } from 'components/src/components/Elements/Modal/Modal';
import { Line as ProgressBar } from 'rc-progress';
import * as React from 'react';
import { ContainerQuery } from 'react-container-query';
import { colors } from '../../Colors/Colors';
import { Button } from '../Buttons/Button/Button';
import { RadioInput } from '../Inputs/Radio/RadioInput';
import { ModalHeader } from '../Modal/Elements/Header/Header';
import {
  BackButton,
  Container,
  NavigationContainer,
  NextButton,
  ProgressBarContainer,
  RadioContainer,
} from './InquiryModal-styled';
import { InquiryModalArrowIcon } from './InquiryModalArrowIcon';

const delay = require('lodash/delay');
const DEFAULT_FINAL_STEP_BUTTON_LABEL = 'Przejdź do aplikacji';

export type InquiryModalData = { title: string, route: string, options: { id: string, label: string }[] };

export type InquiryModalOuterProps = {
  data: InquiryModalData[];
  currentStepIndex: number;
  onSelect: (options: { id: string, label: string }) => void;
  onBackButtonClick: () => void;
  onNextButtonClick: () => void;

  isBackButtonDisabled?: boolean;
  isNextButtonDisabled?: boolean;
  finalStep?: InquiryModalData;
  onFinalStepButtonClick?: () => void;
};

const query = {
  small: {
    maxWidth: 900,
  },
  regular: {
    minWidth: 900,
  },
};

type InquiryModalProps = ModalProps & InquiryModalOuterProps;

export const InquiryModal: React.FC<InquiryModalProps> = props => {
  const [answers, setAnswers] = React.useState({});
  const [isFinalStep, setFinalStep] = React.useState(false);

  const {
    data, currentStepIndex, finalStep,
    isBackButtonDisabled, isNextButtonDisabled,
    onSelect, onBackButtonClick, onNextButtonClick, onFinalStepButtonClick,
    ...modalProps
  } = props;

  const titleLabel = isFinalStep && finalStep
    ? finalStep.title
    : data[currentStepIndex].title;

  return (
    <ContainerQuery
      query={query}
      initialSize={{
        height: exists(window) ? window.innerHeight : 0,
        width: exists(window) ? window.innerWidth : 0,
      }}
    >
      {({ small: smallScreenSize }) => (
        <Modal
          {...modalProps}
          style={{
            content: {
              position: 'relative',
              top: '9rem',
              width: '85rem',
              display: 'flex',
              boxShadow: '0px 3px 6px #00000030',
              borderRadius: smallScreenSize ? '3.6rem' : '4.8rem',
            },
            overlay: { backgroundColor: 'transparent' },
          }}
        >
          <Container size={smallScreenSize ? 'small' : 'medium'}>
            <ProgressBarContainer size={smallScreenSize ? 'small' : 'medium'}>
              <ProgressBar
                percent={(currentStepIndex + 1) / data.length * 100}
                strokeWidth={1.1}
                strokeColor={colors.primary.normal}
                trailColor={colors.primary.light}
                style={{ flex: 1 }}
              />
            </ProgressBarContainer>
            <ModalHeader
              hideCloseIcon
              size={smallScreenSize ? 'small' : 'medium'}
              titleLabel={titleLabel}
            />
            {!isFinalStep && (
              <RadioContainer>
                {data[currentStepIndex].options.map((option, idx) =>
                  <RadioInput
                    key={idx}
                    size={smallScreenSize ? 'medium' : 'small'}
                    value={option.id}
                    label={option.label}
                    checked={answers[currentStepIndex] === option.id}
                    onChange={() => {
                      setAnswers({
                        ...answers,
                        [currentStepIndex]: option.id,
                      });

                      delay(() => {
                        onSelect(option);

                        if ((currentStepIndex + 1) === data.length && finalStep) {
                          setFinalStep(true);
                        }
                      }, 250);
                    }}
                  />)}
              </RadioContainer>
            )}
            <NavigationContainer size={smallScreenSize ? 'small' : 'medium'}>
              {!isFinalStep
                ? (
                  <>
                    <BackButton
                      look="flat"
                      onClick={onBackButtonClick}
                      disabled={isBackButtonDisabled}
                      IconComponent={InquiryModalArrowIcon}
                    />
                    <NextButton
                      onClick={onNextButtonClick}
                      disabled={isNextButtonDisabled}
                      IconComponent={InquiryModalArrowIcon}
                    />
                  </>
                )
                : (
                  <Button
                    onClick={onFinalStepButtonClick}
                  >
                    {(finalStep && finalStep.options[0].label) || DEFAULT_FINAL_STEP_BUTTON_LABEL}
                  </Button>
                )}
            </NavigationContainer>
          </Container>
        </Modal>
      )}
    </ContainerQuery>
  );
};
